<template>
  <q-page padding>
    <header-channel />
    <list-channel />
    <form-channel />
  </q-page>
</template>

<script>
import ListChannel from '../../components/affiliate/ListChannel.vue';
import HeaderChannel from '../../components/affiliate/HeaderChannel.vue';
import ChannelMixin from '../../mixins/ChannelMixin';
import FormChannel from '../../components/affiliate/FormChannel.vue';
export default {
  components: { HeaderChannel, ListChannel, FormChannel },
  name: 'PageChannel',
  mixins: [ChannelMixin]
};
</script>

<style></style>
