<template>
  <q-table
    flat
    bordered
    :data="channels"
    row-key="id"
    :columns="columns"
    :pagination="pagination"
    :visible-columns="visibleColumns"
    selection="multiple"
    :selected.sync="selected"
    class="sticky-column-table"
  >
    <template v-slot:top>
      <q-toolbar>
        <q-btn outline icon="mdi-play" class="q-mr-xs" @click="setStatusChannels(3)" :disabled="selected.length == 0">
          <q-tooltip> Ativar Canal </q-tooltip>
        </q-btn>
        <q-btn outline icon="mdi-pause" @click="setStatusChannels(4)" class="q-mr-xs" :disabled="selected.length == 0">
          <q-tooltip> Pausar Canal </q-tooltip>
        </q-btn>
        <q-btn outline icon="mdi-cancel" :disabled="selected.length == 0" @click="setStatusChannels(5)">
          <q-tooltip> Bloquear Canal </q-tooltip>
        </q-btn>
        <q-space />

        <q-select
          v-model="visibleColumns"
          multiple
          outlined
          dense
          options-dense
          :display-value="$t('customize_columns')"
          emit-value
          map-options
          :options="columns"
          option-value="name"
          options-cover
          style="min-width: 150px"
        >
          <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </q-toolbar>
    </template>

    <template v-slot:bottom>
      <!-- <pagination-table
        @change="getChannels"
        :data="reqPagination"
        :pagination="pagination"
        :gateway="false"
      /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        <div class="row items-center">
          <span class="q-mr-md">Registros por página:</span>
            <q-select
            v-model="pagination.rowsPerPage"
            :options="[10, 20, 50, 100]"
            dense
            @input="onRowsPerPageChange"
            style="max-width: 60px; padding-left: 8px; padding-right: 8px"
            />
           <span>Total: {{ reqPagination.total }}</span>
        </div>
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
    <template v-slot:body-cell-created_at="props">
      <q-td :props="props">
        {{ $moment(props.value).format('L') }}
      </q-td>
    </template>
    <template v-slot:body-cell-action="props">
      <q-td :props="props">
        <q-btn
          unelevated
          round
          flat
          icon="mdi-pencil-outline"
          color="primary"
          @click="ActionSetDialogChannel({ value: props.row })"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
            {{ $t('edit') }}
          </q-tooltip>
        </q-btn>
      </q-td>
    </template>
    <template v-slot:body-cell-status="props">
      <q-td :props="props">
        <q-chip v-if="props.value == 4" color="primary" text-color="white" label="Pausado" />
        <q-chip v-else-if="props.value == 2" color="warning" text-color="white" label="Pendente" />
        <q-chip v-else-if="props.value == 3" color="positive" text-color="white" label="Ativo" />
        <q-chip v-else color="negative" text-color="white" label="Desativado" />
      </q-td>
    </template>
    <template v-slot:body-cell-affiliate_name="props">
      <q-td :props="props">
        <q-item class="q-pa-none">
          <q-item-section>
            <q-item-label><span class="text-weight-bold">Id:</span> {{ props.row.affiliate_id }}</q-item-label>
            <q-item-label><span class="text-weight-bold">Nome:</span> {{ props.row.affiliate_name }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-td>
    </template>
    <template v-slot:body-cell-affiliate_email="props">
      <q-td :props="props">
        <q-item class="q-pa-none">
          <q-item-section>
            <q-item-label> {{ props.row.affiliate_email }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-td>
    </template>
    <template v-slot:body-cell-category="props">
      <q-td :props="props">
        <div class="row q-gutter-sm">
          <q-chip
            dense
            color="primary"
            text-color="white"
            :label="item"
            v-for="(item, index) in props.value.split(';')"
            :key="index"
          />
        </div>
      </q-td>
    </template>
  </q-table>
</template>
<script>
import ChannelMixin from '../../mixins/ChannelMixin';
// import PaginationTable from "../../widgets/PaginationTable.vue";
export default {
  //   components: { PaginationTable },
  name: 'ListChannel',
  mixins: [ChannelMixin],
  data() {
    return {
      selected: [],
      visibleColumns: [],
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },
  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getChannels(`page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
      }
    },
    visibleColumns: {
      handler(newVal) {
        localStorage.setItem('visibleColumns', JSON.stringify(newVal));
      },
      deep: true
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },
  methods: {
    async setStatusChannels(value) {
      this.onLoading(true);
      try {
        const { status } = await this.$http.put(`v2/redirect`, {
          app: 'affiliate',
          path: '/api/edit/site/status/list/',
          params: {
            data: {
              user_id: this.user.user_id,
              status: value,
              list: this.selected.map((item) => item.id).join(',')
            }
          }
        });
        if (status === 200) {
          this.getChannels();
          this.selected = [];
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.onLoading(false);
      }
    },
    toggleOption(opt) {
      const index = this.visibleColumns.indexOf(opt.name);
      if (index === -1) {
        this.visibleColumns.push(opt.name);
      } else {
        this.visibleColumns.splice(index, 1);
      }
    },
    onRowsPerPageChange(value) {
      this.pagination.rowsPerPage = value;
      localStorage.setItem('rowsPerPage', value);
      this.currentPage = 1;
      this.getChannels(`page=1`, `%26perPage=${value}`);
    }
  },
  created() {
    this.pagination.rowsPerPage = parseInt(localStorage.getItem('rowsPerPage')) || 10;
    this.getChannels(null, '%26perPage=' + this.pagination.rowsPerPage);
    const savedColumns = JSON.parse(localStorage.getItem('visibleColumns'));
    if (savedColumns && Array.isArray(savedColumns)) {
      this.visibleColumns = savedColumns;
    } else {
      this.visibleColumns = this.columns
        .filter(column => column.name !== 'cj_id')
        .map(column => column.name);
    }
  }
};
</script>

<style></style>
<style lang="sass">
.sticky-column-table
  max-width: 2600px
  thead tr:last-child th:last-child
    background-color: #fff
  td:last-child
    background-color: #fff
  th:last-child,
  td:last-child
    position: sticky
    right: 0
    z-index: 1
</style>
