<template>
  <div>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Gerenciamento de Canais</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el :label="$t('affiliates')" to="/affiliate" />
        <q-breadcrumbs-el :label="$t('manage_channels')" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <q-toolbar class="q-pr-none q-mb-sm">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        :label="$t('filter')"
        @click="filter = !filter"
      >
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
          {{ $t('filter_creatives') }}
        </q-tooltip></q-btn
      >
      <q-btn outline color="primary" icon-right="archive" class="q-mr-sm" no-caps @click="exportCsv()" unelevated>
        <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
          {{ $t('export_csv') }}
        </q-tooltip>
      </q-btn>
    </q-toolbar>
    <q-card flat v-if="filter" bordered class="q-mb-sm">
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-4">
          <q-input outlined label="Nome do Afiliado" v-model="params.name_affiliate" debounce="700" type="text" />
        </div>
        <div class="col-4">
          <q-input outlined label="Login do Afiliado" v-model="params.username_affiliate" debounce="700" type="text" />
        </div>
        <div class="col-4">
          <q-input outlined label="ID do Afiliado" v-model="params.id_affiliate" debounce="700" type="text" />
        </div>
        <div class="col-4">
          <span class="text-weight-medium q-mb-none">Status</span>
          <div class="q-gutter-xs">
            <q-radio v-model="params.status" val="3" keep-color label="Ativo" class="text-positive" color="positive" />
            <q-radio v-model="params.status" val="2" keep-color label="Pendente" class="text-warning" color="warning" />
            <q-radio v-model="params.status" val="4" keep-color label="Pausado" class="text-info" color="info" />
            <q-radio
              v-model="params.status"
              val="5"
              keep-color
              label="Desativado"
              class="text-negative"
              color="negative"
            />
          </div>
        </div>
        <div class="col-4">
          <q-input outlined label="Nome do Canal" v-model="params.name_channel" debounce="700" type="text" />
        </div>
        <div class="col-2">
          <q-input outlined label="ID do Canal" v-model="params.id_channel" debounce="700" type="text" />
        </div>
        <div class="col-2">
          <q-input outlined label="CJ ID" v-model="params.id_cj" debounce="700" type="text" />
        </div>

        <div class="col-4">
          <select-request
            :label="$t('category')"
            :v-model="params.category"
            outlined
            endpoint="redirect?app=affiliate&path=/api/get/affiliate/filter_site_categories"
            option-label="name"
            option-value="id"
            @update="(value) => (params.category = value)"
          />
        </div>
        <div class="col-4">
          <select-request
            :v-model="params.offer"
            outlined
            label="Vertical"
            endpoint="redirect?app=affiliate&path=/api/get/affiliate/list_site_type"
            option-label="site_type"
            option-value="id"
            @update="(value) => (params.offer = value)"
          />
        </div>
        <div class="col-4">
          <date-input
            :v-model="params.date"
            buttons
            outlined
            range
            label="Perido de cadastro"
            @update="(value) => (params.date = value)"
            clearable
          />
        </div>
        <div class="col-12 text-right">
          <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <!-- <q-btn
            color="positive"
            :label="$t('search')"
            no-caps
            unelevated
            class="q-px-md"
            @click="onFilterChannel"
          /> -->
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import ChannelMixin from '../../mixins/ChannelMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';
export default {
  name: 'HeaderChannel',
  mixins: [ChannelMixin],
  components: { SelectRequest, DateInput },
  data() {
    return {
      filter: true,
      params: {
        name_affiliate: null,
        username_affiliate: null,
        id_affiliate: null,
        name_channel: null,
        id_channel: null,
        id_cj: null,
        name: null,
        type: null,
        campaign: null,
        category: null,
        offer: null,
        bannerSize: null,
        status: null,
        date: null
      }
    };
  },

  watch: {
    params: {
      handler() {
        this.onFilterChannel();
      },
      deep: true
    }
  },

  methods: {
    clearFilter() {
      for (let index in this.params) this.params[index] = null;
      this.ActionSetFilterChannel();
      this.getChannels();
    },
    onFilterChannel() {
      let filterSelect = [];
      if (this.params.name_affiliate) filterSelect.push(`affiliateName=${this.params.name_affiliate}`);
      if (this.params.username_affiliate) filterSelect.push(`affiliateEmail=${this.params.username_affiliate}`);
      if (this.params.id_affiliate) filterSelect.push(`affiliateId=${this.params.id_affiliate}`);
      if (this.params.name_channel) filterSelect.push(`name=${this.params.name_channel}`);
      if (this.params.id_channel) filterSelect.push(`id=${this.params.id_channel}`);
      if (this.params.id_cj) filterSelect.push(`cj_id=${this.params.id_cj}`);
      if (this.params.campaign) filterSelect.push(`campaign=${this.params.campaign.id}`);
      if (this.params.type) filterSelect.push(`type=${this.params.type.id}`);
      if (this.params.category) filterSelect.push(`category=${this.params.category.name}`);
      if (this.params.offer) filterSelect.push(`siteTypeId=${this.params.offer.id}`);
      if (this.params.bannerSize) filterSelect.push(`size=${this.params.bannerSize.id}`);
      if (this.params.status) filterSelect.push(`status%3D%5B%22${this.params.status}%22%5D`);

      if (this.params.date) {
        // %7C
        let startDate = this.params.date.split(' - ')[0].split('/'),
          endDate = this.params.date.split(' - ')[1].split('/'),
          start_date_encoded = `${startDate[2]}-${startDate[1]}-${startDate[0]}`,
          end_date_encoded = `${endDate[2]}-${endDate[1]}-${endDate[0]}`;

        filterSelect.push(`SiteCreateAt=${start_date_encoded}%7C${end_date_encoded}`);
      }

      this.ActionSetFilterChannel(filterSelect.join('%26'));
      this.getChannels();
    },

    async exportCsv() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=affiliate&path=/api/get/site/search?${this.filterChannel}%26page=1%26perPage=99999`
        );
        if (status === 200) this.exportTableCsv(this.prepareItenCSV(data.data), this.columns, 'channels');
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>
