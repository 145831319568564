<template>
  <q-dialog v-model="dialogChannel" persistent>
    <q-card style="min-width: 600px">
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title> Informação do Canal</q-toolbar-title>
        <q-btn flat round dense icon="mdi-close" @click="onClose" />
      </q-toolbar>
      <q-form @submit="onSave">
        <q-card-section class="scroll" style="max-height: 70vh">
          <div class="row q-col-gutter-md">
            <div class="col-9">
              <q-select
                v-model="form.site_type_id"
                use-input
                input-debounce="200"
                @filter="filterSiteType"
                :options="siteTipsFiltered"
                outlined
                label="Vertical"
                option-label="site_type"
                option-value="id"
                clear-icon="mdi-close"
                clearable
                lazy-rules
                :rules="[isRequired]"
              />
            </div>
            <div class="col-3">
              <q-input outlined v-model="form.cj_id" type="text" label="CJ ID" maxlength="20" />
            </div>
          </div>
          <div class="col-12">
            <q-input outlined v-model="form.name" type="text" :label="$t('name')" lazy-rules :rules="[isRequired]" />
          </div>

          <div class="col-12">
            <q-select
              v-model="form.category"
              use-input
              input-debounce="200"
              @filter="filterSiteCategory"
              :options="siteCategoryFiltered"
              label="Categoria de sites"
              option-label="name"
              option-value="id"
              outlined
              lazy-rules
              :rules="[isRequired]"
            />
          </div>
          <div class="col-12">
            <q-input
              outlined
              type="textarea"
              label="URL do canal"
              v-model="form.url"
              lazy-rules
              :rules="[isRequired]"
            />
          </div>
          <div class="col-12">
            <q-input
              outlined
              type="textarea"
              label="Descrição"
              v-model="form.description"
              lazy-rules
              :rules="[isRequired]"
            />
          </div>
        </q-card-section>
        <q-separator />
        <q-card-actions align="right" class="q-pa-md">
          <q-btn outline :label="$t('cancel')" no-caps padding="sm lg" color="primary" @click="onClose" />
          <q-btn unelevated label="Atualizar informações" no-caps padding="sm lg" color="primary" type="submit" />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import ChannelMixin from '../../mixins/ChannelMixin';
import AffiliateService from '../../services/AffiliateService';

export default {
  name: 'FormChannel',
  mixins: [ChannelMixin, AffiliateService],
  data() {
    return {
      form: {
        name: null,
        category: null,
        description: null,
        site_type_id: null,
        id: null,
        url: null,
        cj_id: null
      },
      roles: [],
      loading_role: false,
      loading_profile: false,
      profiles: [],
      siteTips: [],
      siteTipsFiltered: [],
      siteCategory: [],
      siteCategoryFiltered: []
    };
  },
  methods: {
    filterSiteType(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.siteTipsFiltered = this.siteTips;
        this.siteTipsFiltered = this.siteTips.filter((v) => v.site_type.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterSiteCategory(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.siteCategoryFiltered = this.siteCategory;
        this.siteCategoryFiltered = this.siteCategory.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    onClose() {
      this.ActionSetDialogChannel(false);
    },
    async onSave() {
      this.onLoading(true);

      try {
        let formEdit = this.form;
        formEdit.site_type_id = this.form.site_type_id.id;
        formEdit.category = this.form.category.id;

        delete formEdit.id;

        const { status } = await this.editAffiliateChannel(this.channelSelect.id, formEdit);
        if (status === 200 || status === 201) {
          this.successNotify('Canal atualizado com sucesso');
        }
      } catch (error) {
        if (error.response.data.errors !== undefined)
          Object.keys(error.response.data.errors).forEach((key) => {
            this.errorNotify(error.response.data.errors[key]);
          });
      } finally {
        await this.onSetup();

        this.onLoading(false);
      }
    },
    async getSiteTips() {
      try {
        const { data, status } = await this.getAffiliateSiteTips();
        if (status === 200 || status === 201) {
          this.siteTips = data;
          this.siteTipsFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },
    async getSitesCategory() {
      try {
        const { data, status } = await this.getAffiliateSiteCategoryFilter();
        if (status === 200 || status === 201) {
          this.siteCategory = data;
          this.siteCategoryFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },
    async onSetup() {
      this.onLoading(true);

      await this.getSiteTips();
      await this.getSitesCategory();

      try {
        const { data, status } = await this.getAffiliateSiteData(this.channelSelect.id);
        if (status === 200 || status === 201) {
          this.form = data;

          this.form.site_type_id = this.siteTips.find((item) => item.id === parseInt(data.site_type_id));
          this.form.category = this.siteCategory.find((item) => item.id === parseInt(data.category));
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  watch: {
    channelSelect() {
      if (this.channelSelect !== null) {
        this.onSetup();
      }
    }
  }
};
</script>

<style></style>
